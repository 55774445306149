.title{
	margin-bottom: 30px;
	color: $color--background;
	font-weight: 600;
}

.seccion{
	padding-top: 25px;
	padding-bottom: 25px;
}

.tarjeta{
	text-align: center;
	padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 3px 3px 10px darkgrey;
    cursor: pointer;
    height: 100%;
    width: 100%;
    @include transition(400ms);
    .image{
    	margin-bottom: 3em;
    	font-size: 1.2em;
    	color: aqua;
    }
    &:hover{
    	@include cp-property(transform,scale(1.05));
    	opacity: 0.7;
    }
    .descript{
    	color: $color--text;
    }
}