#instalacion{
	padding-top: 30px;
	padding-bottom: 30px;
	margin-top: 80px;
	color: $color--text;
	.title{
		margin-bottom: 8px;
	}
	.text{
		margin-bottom: 25px;
	}
	.subtitle{
		font-weight: bold;
		font-size: 1.4rem;
		color: gray;
	}
}